@import "../../../../Assets/css/Theme.scss";

.statistic_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding: 30px;
  border-radius: 16px;
  column-gap: 5px;
  aspect-ratio: 1/1;

  .statistic_icon {
    width: 80px;
  }
  .statistic_body {
    display: flex;
    flex-direction: column;
    align-items: start;
    .statistic_number {
      font-size: 2.3rem;
      font-weight: 700;
      margin: 0;
    }
    .statistic_details {
      font-size: 16px;
      font-weight: 500;
      color: $gray;
    }
  }
}
