@import "../../Assets/css/Theme.scss";

.footer_container {
  background-color: $whitish;
}

.footer_header {
  font-size: 16px;
  font-weight: 600;
}
.footer_list {
}
.footer_item {
  font-weight: 400;
  .footer_link {
    text-decoration: none;
    color: $gray;
    font-size: 16px;

    &:hover {
      color: $blue;
    }
    .footer_social_icon {
      font-size: 24px;
    }
  }
}

.footer_store_icon {
  width: 100%;
}

.footer_paragraph {
  color: $gray;
}

.footer_form {
  background-color: $blueish;
  //position: relative;
  padding: 2px;
  border-radius: 10px;

  .footer_formgroup {
    width: 100%;
    .footer_input {
      border: none;
      border-radius: 0;
      background-color: transparent;
    }
  }
}
