@import "../../Assets/css/Theme.scss";

.lato-thin {
  font-family: "Lato", serif;
  font-weight: 100;
  font-style: normal;
  margin: 0;
}

.lato-light {
  font-family: "Lato", serif;
  font-weight: 300;
  font-style: normal;
  margin: 0;
}

.lato-regular {
  font-family: "Lato", serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
}

.lato-bold {
  font-family: "Lato", serif;
  font-weight: 700;
  font-style: normal;
  margin: 0;
}
.lato-black {
  font-family: "Lato", serif;
  font-weight: 900;
  font-style: normal;
  margin: 0;
}

.lato-thin-italic {
  font-family: "Lato", serif;
  font-weight: 100;
  font-style: italic;
  margin: 0;
}

.lato-light-italic {
  font-family: "Lato", serif;
  font-weight: 300;
  font-style: italic;
  margin: 0;
}

.lato-regular-italic {
  font-family: "Lato", serif;
  font-weight: 400;
  font-style: italic;
  margin: 0;
}

.lato-bold-italic {
  font-family: "Lato", serif;
  font-weight: 700;
  font-style: italic;
  margin: 0;
}

.lato-black-italic {
  font-family: "Lato", serif;
  font-weight: 900;
  font-style: italic;
  margin: 0;
}

/* Rectangle 974 */
.main-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.card-container {
  /* width: 345px; */
  max-width: 350px;
  /* background-color: #ffffff; */
  border-radius: 30px;
  .card-body {
    background: linear-gradient(341.46deg, #005de9 -0.72%, #327ae7 101.96%);
    border-radius: 30px;

    .card-navigation {
      width: 100%;
      padding: 20px 20px 10px 20px;
      .navigation-btn {
        height: 32px;
        width: 32px;
        background-color: transparent;
        border: 2px solid #fff;
        border-radius: 23px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .navigation-icon {
          color: #fff;
          font-size: 18px;
        }
      }
    }
    .card-header {
      padding: 5px 20px;

      & .inner {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        .left-header {
          display: flex;
          flex-direction: column;
          gap: 2px;
          color: #fff;
          .header-text {
            font-size: 28px;
            line-height: 24px;
            margin: 0;
          }
          .header-date {
            font-size: 14px;
          }
        }
        .right-header {
          .profile {
            width: 54px;
            height: 54px;
            border-radius: 60px;
            background-color: white;
            border: 2px solid #81b3ff;
            background-color: transparent;
            padding: 3px;

            .profile-inner {
              width: 100%;
              height: 100%;
              background-color: #f5f5f5;
              border-radius: 50%;
            }
          }
        }
      }
    }
    .card-body-section {
      padding: 10px;
      position: relative;
      flex: 1;

      .body-3d-shadow-1 {
        width: 78%;
        height: 50px;
        border-radius: 30px;
        position: absolute;
        top: 58px;
        background-color: #6fa9ff;
        z-index: 99;
        left: 50%;
        transform: translateX(-50%);
      }
      .body-3d-shadow-2 {
        width: 66%;
        height: 50px;
        border-radius: 30px;
        position: absolute;
        top: 48px;
        background-color: #81b3ff;
        z-index: 98;
        left: 50%;
        transform: translateX(-50%);
      }
      .body-container {
        position: relative;
        margin-top: 60px;
        width: 100%;
        background-color: white;
        /* height: 400px; */
        border-radius: 22px;
        z-index: 100;
        flex: 1;
        .body-top {
          display: flex;
          flex-direction: column;
          flex: 1;

          .body-header {
            display: flex;
            flex-direction: column;
            padding: 10px 10px 0 10px;
            .header-top {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              .header-rule {
                font-size: 13px;
              }
              .header-callout {
                background-color: #d9d9d9;
                border-radius: 20px;
                display: flex;
                flex-direction: row;
                gap: 7px;
                padding: 2px;
                .header-btn {
                  font-size: 20px;
                  background-color: transparent;
                  border: none;
                  height: 30px;
                  width: 30px;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  .callout-icon {
                    font-size: inherit;
                  }
                }
              }
            }
            .header-bottom {
              width: 100%;
              padding: 10px 0;
              .main-header {
                font-size: 18px;
                line-height: 16px;
              }
            }
          }
          .body-details {
            padding: 10px;
          }
        }
        .body-footer {
          .footer-top {
            border-top: 1px solid #9e9ea4;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 10px 0;
            background-color: transparent;

            .footer-top-text {
              font-size: 12px;
              color: #9e9ea4;
            }
          }
          .footer-bottom {
            width: 100%;
            background-color: #9e9ea4;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 10px 0;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;

            .footer-bottom-text {
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  /* footer */
  .card-footer {
    padding: 10px 5px;

    .footer-inner {
      display: flex;
      flex-direction: row;
      gap: 10px;
      .cancel-btn {
        height: 54px;
        width: 60px;
        border-radius: 16px;
        border: none;
        background-color: #ff5b2b;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 16px;

        .cancel-icon {
          font-size: 32px;
        }
      }
      .accept-btn {
        width: 100%;
        border: none;
        background-color: #005de9;
        color: white;
        border-radius: 16px;
        font-size: 16px;
      }
    }
  }
}
