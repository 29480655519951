@import "../../Assets/css/Theme.scss";

$borderradius: 18px;

.card_box {
  height: auto;
  border: none;
  border-radius: $borderradius;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  -webkit-transition: all ease-out 0.5s;
  -moz-transition: all ease-out 0.5s;
  -o-transition: all ease-out 0.5s;
  transition: all ease-out 0.5s;

  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    -webkit-transition: all ease-out 0.5s;
    -moz-transition: all ease-out 0.5s;
    -o-transition: all ease-out 0.5s;
    transition: all ease-out 0.5s;
  }
}

.card_price {
  position: absolute;
  font-size: 12px;
  background-color: $blue;
  padding: 5px 10px;
  color: $white;
  border-radius: 6px;
  top: 16px;
  left: 16px;
  display: flex;
  flex-direction: row;
  gap: 3px;
}

.card_image {
  height: 300px;
  border-top-left-radius: $borderradius;
  border-top-right-radius: $borderradius;
}

.card_subtitle {
  color: $gray;
  font-size: 14px;
  font-weight: 400;
}
.card_title {
  color: $black;
  font-size: 20px;
  font-weight: 600;
}

.card_paragraph {
  font-size: 16px;
  color: $gray;
}

.card_time {
  display: flex;
  flex-direction: row;
  gap: 6px;
  font-size: 16px;
  color: $gray;
}

.card_rating {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 16px;
  font-weight: 600;
  color: $gray;

  .rating_icon {
    color: $orange;
  }
}
