@import "../../Assets/css/Theme.scss";

.section_header {
  margin: 0;
  width: auto;
  font-weight: 700;
  font-size: 3rem;
  color: $black;
  text-align: center;
}
.section_subtitle {
  margin: 0;
  width: auto;
  font-weight: 500;
  color: $gray;
  text-align: center;
}
