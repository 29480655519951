@import "./Assets/css/Theme.scss";

.App {
  font-family: "Maven Pro", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.page_container {
  position: relative;
}

.main_container {
  margin-top: 70px;
}

.main_navbar {
  background-color: transparent !important;
  -webkit-transition: all ease-out 0.5s;
  -moz-transition: all ease-out 0.5s;
  -o-transition: all ease-out 0.5s;
  transition: all ease-out 0.5s;

  &.scrolled {
    background-color: $white !important;
    -webkit-transition: all ease-out 0.5s;
    -moz-transition: all ease-out 0.5s;
    -o-transition: all ease-out 0.5s;
    transition: all ease-out 0.5s;
  }
}

.page_header {
  font-size: 36px;
  font-weight: 700;
  color: $blue;
  margin: 0;
}

.Simple_header {
  font-size: 24px;
  font-weight: 600;
  color: $black;
  margin: 0;
  text-transform: uppercase;
}

.primary_button {
  background-color: $blue;
  font-weight: 600;
  border-radius: 10px;
}
